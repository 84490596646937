export enum Marital {
  MARRIED = 'married',
  SINGLE = 'single',
  DIVORCED = 'divorced',
  RELATIONSHIP = 'relationship',
}

export const humanizeMarital = {
  [Marital.MARRIED]: 'Married',
  [Marital.SINGLE]: 'Single',
  [Marital.DIVORCED]: 'Divorced',
  [Marital.RELATIONSHIP]: 'In a relationship',
}
