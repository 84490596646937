export interface IQueryParams {
  query?: string;
  page?: number;
  limit?: number;
  sort?: string;
  order?: 'asc' | 'desc';
  searchKeys?: string[];
}

export enum QueryFilterType {
  STRING = 'string',
  DATE = 'date',
}

export interface FilterArray {
  arrContains: string[];
  oneOf: string[];
  equal: string[] | string;
}

interface FilterDate {
  from: Date;
  to: Date;
}

interface FilterEqual {
  equal: string | boolean;
}

interface FilterExist {
  exist: boolean;
}

export interface QueryFilters {
  [key: string]: FilterDate | FilterEqual | FilterArray | FilterExist;
}

export class QueryParams {
  query?: string;
  page?: number;
  limit?: number;
  sort?: string;
  token?: string;
  order?: 'asc' | 'desc';
  searchKeys?: string[] = [];
  filters?: QueryFilters;

  constructor(query = '') {
    this.query = query;
    this.page = 1;
  }
}

export class PaginatedData<T> {
  data: T[] = [];
  currPage = 1;
  totalPages = 0;
  totalItems = 0;
}
