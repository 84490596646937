import { Component, OnInit, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { Snackbar } from '@services/snackbar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit {
  @ViewChild('snack') snack: ElementRef;
  content: Snackbar = {
    title: '',
    message: '',
    type: 'info',
    _snackRef: null
  };

  constructor(
      @Inject(MAT_SNACK_BAR_DATA) public data: Snackbar,
  ) {
  }

  ngOnInit() {
    if (this.data) this.content = this.data;
  }

  dismiss() {
    this.data._snackRef.dismiss();
    // workaround to remove element from DOM on chunk error
    this.snack.nativeElement.remove();
  }
}
