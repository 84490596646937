export enum QueryParamsProperty {
  FILTERS = 'filters',
  ACTIVE_TAB = 'activeTab',
  QUERY_PARAMS = 'queryParams',
}

export enum Page {
  MEMBERS_PROFILE = '[Members Profile]',
  MEMBERS_STATUS = '[Members Status]',
  MEMBERS_SUBSCRIPTIONS = '[Members Subscriptions]',
  MEMBERS_BILLING = '[Members Billing]',
  MEMBERS_EMAILS = '[Members Emails]',
  MEMBERS_POSTS = '[Members Posts]',
  MEMBERS_REPLIES = '[Members Replies]',
  MEMBERS_VIEWS = '[Members Views]',
  POST_REPLIES = '[Post Replies]',
  POST_VIEWS = '[Post Views]',
  FREE_TRIALS_REFERRAL_LINKS = '[Free Trials Referral Links]',
  POST_TYPES = '[Post Types]',
  ROOT_POSTS = '[Root Posts]',
  ROOT_TYPES = '[Root Types]',
  ROOT_PEPLIES = '[Root Replies]',
  ROOT_STATUSES = '[Root Statuses]',
  ROOT_REFERRAL_LINKS = '[Root Referral Links]',
  ROOT_FREE_TRIALS = '[Root Free Trials]',
  ROOT_POTENTIAL_USERS = '[Root Potential Users]',
  ROOT_USERS = '[Root Users]',
  ROOT_ADMINS = '[Root Admins]',
  LOCATION_USERS = '[Location Users]',
}

export type Params = { [key in Partial<QueryParamsProperty>]: QueryParamsValue };

export interface QueryParamsValue {
  [key: string]: any;
}
