import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CommonSchema, FilterArray, MatOption, QueryParams } from '@interfaces';
import { CustomValidators } from '@utility';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Observable, EMPTY, throwError } from 'rxjs';
import { expand, finalize, takeWhile } from 'rxjs/operators';
import { ExportIterationResp } from '@services/export/export.service';

interface Obj extends CommonSchema {
  [key: string]: any;
}

export interface FilterCmpOption<T, Y = string> extends Partial<MatOption> {
  value: keyof T & Y & string;
  type: 'string' | 'date' | 'select';
  selectOpts?: {
    optionsArr?: MatOption[];
    multiply?: boolean;
    type: 'oneOf' | 'arrContains' | 'equal' | 'exist';
  };
  validators?: ValidatorFn[];
  // specific value for request, because of error on set form control name another than camelCase
  // @example:  formControl - 'userFirstName' --> in request - 'user.firstName'
  dotValue?: string;
}

export type FilterCmpSearchKey<T> = keyof T | MatOption<keyof T>;

const commonFilters: FilterCmpOption<CommonSchema>[] = [
  {
    value: 'createdAt',
    viewValue: 'created',
    type: 'date',
  },
  {
    value: 'updatedAt',
    viewValue: 'updated',
    type: 'date',
  },
  {
    value: '_id',
    viewValue: 'object ID',
    type: 'string',
    validators: [Validators.pattern(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i)],
  },
];

interface FiltersForm {
  [key: string]: { from: Moment | undefined; to: Moment | undefined } & { equal: (MatOption & string) | undefined } & {
    select: MatOption | undefined;
  };
}

export interface FilterCmpExportParam {
  method: (qp?: QueryParams) => Observable<Blob | ExportIterationResp>;
  viewValue?: string;
  fileName?: string;
}

export class AssignedFilterState {
  query: string = null;
  searchKey: Array<string> = [];
  filters: Array<{ name: string; value: any }> = [];
}

export type AssignedSearchParams = { key: string; query: string };

export enum FiltersChangeSourse {
  api = 'api',
  user = 'user',
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnChanges {
  @Input() exportParams: FilterCmpExportParam[];
  @Input() set searchKeys(value: FilterCmpSearchKey<Obj>[]) {
    this._searchKeys = value;
    this.fetchAvailableFilters();
    if (this._searchKeys) {
      this.handleSearchKeys();
    }
  }
  get searchKeys() {
    return this._searchKeys;
  }
  @Input() set filters(value: FilterCmpOption<Obj>[]) {
    this._filters = value;
    this.fetchAvailableFilters();
    if (this._assignedFilters) {
      this.handleAssignedFilters();
    }
  }
  get filters() {
    return this._filters;
  }
  @Input() set assignedFilters(value: AssignedFilterState) {
    this._assignedFilters = value || ({} as AssignedFilterState);
    this.fetchAvailableFilters();
    if (value) {
      this.handleAssignedFilters();
    }
  }
  get assignedFilters() {
    return this._assignedFilters;
  }

  private _filters: FilterCmpOption<Obj>[] = [];
  private _searchKeys: FilterCmpSearchKey<Obj>[] = [];
  private _assignedFilters: AssignedFilterState = {} as AssignedFilterState;
  countdown: number;
  exportProcessMsg: string;

  searchKeysOpts: MatOption<keyof Obj>[] = [];
  availableFilters: FilterCmpOption<Obj>[] = [];
  selectedFilters: FilterCmpOption<Obj>[] = [];

  form = this.fb.group({
    query: ['', [Validators.minLength(2)]],
    searchKey: [null],
    filters: this.fb.group({}),
  });

  acts = { isLoading: false };

  @Input() filtersChangeSourse: FiltersChangeSourse;
  @Output() filtersChangeSourseChange = new EventEmitter<FiltersChangeSourse>();

  @Output() emitRequest = new EventEmitter<QueryParams>();

  constructor(private fb: FormBuilder, private change: ChangeDetectorRef) {}

  ngOnInit() {
    // Prepare filters
    this.fetchAvailableFilters();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fetchAvailableFilters();
  }

  addFilter(filter: FilterCmpOption<Obj>, initialValue?: any) {
    if (this.filtersForm.contains(filter.value)) return;

    switch (filter.type) {
      case 'date':
        const dateCtrl = this.fb.group({
          from: [null],
          to: [null, CustomValidators.diffDates('gte', 'from')],
        });
        if (initialValue) dateCtrl.patchValue(initialValue);
        this.filtersForm.addControl(filter.value, dateCtrl);
        break;
      case 'string':
        const stringCtrl = this.fb.group({ equal: [null, filter.validators] });
        if (initialValue) stringCtrl.patchValue(initialValue);
        this.filtersForm.addControl(filter.value, stringCtrl);
        break;
      case 'select':
        const selectCtrl = this.fb.group({ select: [null] });
        if (initialValue) selectCtrl.patchValue(initialValue);
        this.filtersForm.addControl(filter.value, selectCtrl);
        break;
    }
    this.selectedFilters.push(filter);
    this.fetchAvailableFilters();
  }

  get filtersForm(): FormGroup {
    return this.form.get('filters') as FormGroup;
  }

  removeFilter(filter: FilterCmpOption<Obj>, index: number) {
    this.filtersForm.removeControl(filter.value);
    this.selectedFilters.splice(index, 1);
    this.fetchAvailableFilters();
    this.submit();
  }

  fetchAvailableFilters() {
    this.availableFilters = [...this.filters, ...commonFilters].filter(
      (f) => !this.selectedFilters.some((sf) => sf.value === f.value)
    );
  }

  private handleAssignedFilters(): void {
    if (this.assignedFilters?.query) {
      this.form.get('query').setValue(this.assignedFilters?.query);
    }
    if (this.assignedFilters?.searchKey && this.assignedFilters?.searchKey[0]) {
      const key = this.searchKeysOpts?.find((opt) =>
        this.assignedFilters?.searchKey.some((_key) => _key === opt.value)
      );
      this.form.get('searchKey').setValue(key);
    }
    this.assignedFilters?.filters?.forEach((assignedFilter: any) => {
      const filter: FilterCmpOption<Obj> = this.availableFilters.find(
        (_filter: FilterCmpOption<Obj>) =>
          _filter.value === assignedFilter.name || _filter.dotValue === assignedFilter.name
      );
      if (filter) {
        if (filter.type === 'select') {
          const value: any = Object.values(assignedFilter.value)[0];
          const option: any = filter.selectOpts.multiply
            ? filter.selectOpts.optionsArr.filter((opt) => value?.some((_value) => _value === opt.value))
            : filter.selectOpts.optionsArr.find((opt) => opt.value === value);
          if (value) assignedFilter.value = { select: option };
        }
        this.addFilter(filter, assignedFilter.value);
      }
    });
    this.submit(FiltersChangeSourse.api);
  }

  private handleSearchKeys(): void {
    this.searchKeysOpts = this.mapSearchKeys(this.searchKeys);
    const searchKeyCtrl = this.form.get('searchKey');
    searchKeyCtrl.setValue(this.searchKeysOpts[0]);
    if (this.searchKeysOpts.length < 2) searchKeyCtrl.disable();
    if (!this.searchKeysOpts.length) this.form.get('query').disable();
  }

  private mapSearchKeys(searchKeys: FilterCmpSearchKey<Obj>[]) {
    return searchKeys.map((key) => {
      if (typeof key === 'object') return key;
      if (typeof key === 'string') return { value: key.toLowerCase(), viewValue: key };
    });
  }

  submit(source: FiltersChangeSourse = FiltersChangeSourse.user) {
    if (this.form.invalid) return;
    const query = this.form.get('query').value;
    const searchKeys = [this.form.get('searchKey').value?.value];

    // prepare filters
    const filtersVal: FiltersForm = this.filtersForm.value;
    const filters: QueryParams['filters'] = {};
    this.selectedFilters.forEach((f) => {
      const valName = f.value;
      const filterName = f.dotValue || f.value;
      switch (f.type) {
        case 'date':
          let { from, to } = filtersVal[valName];
          from = from ? moment(from) : undefined;
          to = to ? moment(to) : undefined;
          filters[filterName] = {
            from: from?.startOf('day').toDate(),
            to: to?.endOf('day').toDate(),
          };
          break;
        case 'select':
          const { select } = filtersVal[valName];
          const value = Array.isArray(select) ? select.map((s) => s.value) : select?.value;
          filters[filterName] = { [f.selectOpts.type]: value } as FilterArray;
          break;
        case 'string':
          const { equal } = filtersVal[valName];
          filters[filterName] = {
            equal: equal || undefined, // prevent null | ''
          };
          break;
      }
    });
    this.filtersChangeSourseChange.emit(source);
    this.emitRequest.emit({ query, searchKeys, filters } as QueryParams);
  }

  onExport(param: FilterCmpExportParam) {
    this.acts.isLoading = true;

    let intervalId;
    const initCountdown = (seconds: number) => {
      if (intervalId) clearInterval(intervalId);
      if (!seconds) return;
      this.countdown = seconds;
      intervalId = setInterval(() => this.countdown--, 1000);
    };

    const clearCountdown = () => {
      if (intervalId) clearInterval(intervalId);
      this.countdown = 0;
    };

    const onComplete = (scope) => {
      // console.log('onComplete', scope?.error, scope);
      this.acts.isLoading = false;
      if (scope?.error) clearCountdown();
    };

    const useBlob = (blob: Blob) => {
      const link = document.createElement('a');
      link.download = param.fileName || `export_${+new Date()}`;
      link.href = URL.createObjectURL(blob);
      link.click();
    };

    const request = (data?) => {
      this.acts.isLoading = true;
      return param
        .method(data)
        .pipe(
          expand((resp: ExportIterationResp) => {
            if (data?.token && data.token !== resp.token && !data?.done) {
              console.log(`FILTER ERR:: passed export method doesn't merge query params.`);
              console.log('(qp: QueryParams) => this.srv.exportAll({ ...qp, ...this.querySrv.queryParams })');
              return throwError('FILTER ERR');
            }
            return resp.token ? request(resp) : EMPTY;
          })
        )
        .pipe(takeWhile(() => false, true))
        .pipe(finalize(() => onComplete('final')))
        .subscribe(
          (resp: ExportIterationResp) => {
            this.acts.isLoading = true;
            const { token, description, timeLeft } = resp;
            if (token) {
              this.exportProcessMsg = description;
              if (timeLeft) initCountdown(timeLeft);
            } else if (data.done) {
              useBlob((resp as unknown) as Blob);
              onComplete('success');
              clearCountdown();
            }
          },
          (err) => onComplete(err)
        );
    };

    request();
  }
}
