export interface IBlobFile extends Blob {
  url: string;
  public_id: string;
  type: 'image' | 'video';
}

export interface IFileData {
  base64: string | ArrayBuffer | null;
  file: IBlobFile;
  files: IBlobFile[];
}

export enum FileErrorCode {
  type = 1
}

export interface IFileDataError {
  code: FileErrorCode;
  data?: any;
}
