<div class="input-container input-type-{{ inputType }} {{ wrapperCssClass }}">
  <mat-form-field>
    <!-- TODO REFACT!!!! -->
    <mat-icon class="search-icon" matPrefix *ngIf="inputType === 'search'">search</mat-icon>
    <mat-label>{{ label }}</mat-label>

    <!-- INPUT -->
    <input
      matInput
      [placeholder]="placeholder"
      [type]="inputType"
      [formControl]="fControl"
      [required]="required"
      [autocomplete]="attrAutocomplete || inputType"
      (keypress)="inputType === 'number' ? numbersOnly($event) : null"
      [min]="min"
      *ngIf="['text', 'password', 'email', 'tel', 'number', 'url', 'search'].includes(inputType)"
    />
    <!--<mat-icon matSuffix>&#163;</mat-icon>-->
    <span matSuffix *ngIf="suffix">{{ suffix }}</span>

    <!-- TEXTAREA -->
    <textarea matInput [placeholder]="placeholder" [formControl]="fControl" *ngIf="inputType === 'textarea'">
    </textarea>

    <!-- SELECT -->
    <mat-select
      [placeholder]="placeholder"
      [formControl]="fControl"
      [multiple]="multiple"
      [compareWith]="compareWith"
      (selectionChange)="onChangeSelect($event)"
      (focus)="beforeChangeSelect()"
      (closed)="onCloseSelect()"
      *ngIf="inputType === 'select'"
    >
      <mat-select-trigger *ngIf="multiple">
        {{ fControl.value?.length ? fControl.value[0].viewValue : '' }}
        <span class="label" *ngIf="fControl.value?.length > 1">
          (+{{ fControl.value.length - 1 }} {{ fControl.value?.length === 2 ? 'other' : 'others' }})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let option of optionsArr" [value]="option" [disabled]="option.disabled">
        {{ option.viewValue }}
      </mat-option>
    </mat-select>

    <!-- TODO remove! -->
    <!-- AUTOCOMPLETE -->
    <input
      matInput
      type="text"
      [placeholder]="placeholder"
      [formControl]="fControl"
      [matAutocomplete]="autoInput"
      *ngIf="inputType === 'autocomplete'"
    />

    <!-- ERROR MESSAGE // don't use *ngIf because of ExpressionChangedAfterItHasBeenCheckedError-->
    <mat-error [style.display]="fControl.invalid ? 'inline-block' : 'none'">
      {{ fControl.errors | getErrorMessage | stringNormalizer }}
    </mat-error>

    <!-- CLEAR BUTTON -->
    <button
      type="button"
      aria-label="Clear"
      mat-button
      matSuffix
      mat-icon-button
      (click)="fControl.setValue('')"
      *ngIf="clearBtn && fControl.value"
    >
      <mat-icon>close</mat-icon>
    </button>

    <!-- SHOW PASSWORD BUTTON -->
    <button
      class="show-password-btn"
      type="button"
      aria-label="Clear"
      matSuffix
      mat-icon-button
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="showPasswordBtn"
      (click)="togglePasswordVisibility()"
      *ngIf="showPasswordBtn"
    >
      <mat-icon>{{ inputType === 'password' ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>

    <!-- HINT BUTTON -->
    <button
      class="show-hint-btn"
      type="button"
      aria-label="hint"
      mat-button
      matSuffix
      mat-icon-button
      *ngIf="hint"
      [matTooltip]="hint"
    >
      <mat-icon>help_outline</mat-icon>
    </button>
  </mat-form-field>

  <!-- AUTOCOMPLETE options // should be render on init -->
  <mat-autocomplete #autoInput="matAutocomplete" [displayWith]="displayOption">
    <ng-container *ngIf="isAutocomplete && fControl.value?.length > 1">
      <mat-option *ngIf="isSearching" disabled>
        <app-waiting [size]="40"></app-waiting>
      </mat-option>
      <mat-option *ngIf="fControl.value && !isSearching && !optionsArr.length" disabled>Not found</mat-option>
    </ng-container>
    <mat-option *ngFor="let option of optionsArr" [value]="option">{{ option.viewValue }}</mat-option>
  </mat-autocomplete>
</div>
