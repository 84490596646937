import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core.module';
import { SharedModule } from './modules/shared.module';
import { StoreModule } from '@ngrx/store';
import { reducer } from '@store/query-params/query-params.reducer';
import { environment } from 'src/environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { QueryParamsPreservingApi } from '@api/query-params-preserving/query-params-preserving.api';
import { QueryParamsPreservingService } from '@services/query-params-preserving/query-params-preserving.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature('query-params', reducer),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
  ],
  providers: [{ provide: QueryParamsPreservingApi, useClass: QueryParamsPreservingService }],
  bootstrap: [AppComponent],
})
export class AppModule {}
