import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { Page, QueryFilters, QueryParams, QueryParamsProperty, QueryParamsValue } from '@interfaces';
import { Paginator, AdminsRoute } from '@const';
import { AssignedFilterState, FilterCmpOption, FiltersChangeSourse } from '@components/filters';
import { QueryService } from '@services/query';
import { Admin } from '../..';
import { AdminsService } from '../../service';
import { SnackbarService } from '@services/snackbar';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { QueryParamsPreservingApi } from '@api/query-params-preserving/query-params-preserving.api';

type AdminProps = keyof Admin;

@Component({
  selector: 'app-admins',
  templateUrl: './admins-list.component.html',
  styleUrls: ['./admins-list.component.scss'],
  providers: [QueryService],
})
export class AdminsListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // passed predefined query filters
  @Input() set queryFilters(value: QueryFilters) {
    this._queryFilters = value;
    this._filters.next(value);
  }
  get queryFilters() {
    return this._queryFilters;
  }

  @Input() set pageIdentifier(page: Page) {
    this._pageIdentifier = page;
    this.handleQueryParams();
  }
  get pageIdentifier() {
    return this._pageIdentifier;
  }

  filtersChangeSourse: FiltersChangeSourse = FiltersChangeSourse.api;

  private _queryFilters: QueryFilters;
  private initialPageIndentifier: Page = Page.ROOT_ADMINS;
  private _pageIdentifier: Page = this.initialPageIndentifier;
  private _filters: BehaviorSubject<QueryFilters> = new BehaviorSubject(null);
  private _paginatorPage$: BehaviorSubject<number> = new BehaviorSubject(1);

  Route = AdminsRoute;
  Paginator = Paginator;

  dataSource = new MatTableDataSource<Admin>([]);
  displayedColumns: AdminProps[] = ['avatar', 'name', 'email', 'phone', 'createdAt'];

  filterSearchKeys: AdminProps[] = ['name', 'email', 'phone'];
  filters: FilterCmpOption<Admin>[] = [];
  assignedFilters: AssignedFilterState;

  acts = {
    isLoading: true,
  };

  constructor(
    private adminSrv: AdminsService,
    private querySrv: QueryService,
    private snackBarSrv: SnackbarService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private queryParamsPreservingApi: QueryParamsPreservingApi
  ) {}

  ngOnInit() {
    if (this.pageIdentifier === this.initialPageIndentifier) {
      this.handleRootQueryParams();
    }
  }

  ngAfterViewInit() {
    const request = (params: QueryParams) => {
      if (this.queryFilters) params.filters = { ...params.filters, ...this.queryFilters };
      this.updateQueryParams({ ...params });
      return this.adminSrv.getAll(params);
    };

    this.querySrv
      .register({
        sort: this.sort,
        paginator: this.paginator,
        request,
        acts: this.acts,
      })
      .pipe(untilDestroyed(this))
      .subscribe(({ data }) => (this.dataSource = new MatTableDataSource<Admin>(data)));
    this.querySrv.initSearch();
  }

  private handleQueryParams(): void {
    this.queryParamsPreservingApi.setActivePage(this.pageIdentifier);
    this.subscribeToQueryParamsChanges();
  }

  private subscribeToQueryParamsChanges(): void {
    this.queryParamsPreservingApi.activePageQueryParams$
      .pipe(untilDestroyed(this), take(1))
      .subscribe((queryParams: QueryParamsValue) => {
        const page: number = queryParams?.queryParams?.page;
        if (page) {
          this._paginatorPage$.next(page);
        }
        if (queryParams?.queryParams) {
          this.updateAssignedFilters(queryParams?.queryParams);
        }
      });
  }

  private updateQueryParams(queryParams: QueryParams): void {
    this.queryParamsPreservingApi.update(
      this.pageIdentifier,
      { [QueryParamsProperty.QUERY_PARAMS]: { ...queryParams } },
      'merge'
    );
  }

  private updateAssignedFilters(queryParams: QueryParamsValue): void {
    const assignedFilters: AssignedFilterState = new AssignedFilterState();
    Object.keys(queryParams?.filters || {}).forEach((prop: string) => {
      assignedFilters.filters.push({ name: prop, value: queryParams?.filters[prop] });
    });
    assignedFilters.query = queryParams.query;
    assignedFilters.searchKey = queryParams.searchKeys;
    if (this.pageIdentifier === this.initialPageIndentifier) {
      this.assignedFilters = assignedFilters;
    } else {
      this._filters.pipe(filter(Boolean), take(1)).subscribe(() => {
        this.assignedFilters = assignedFilters;
      });
    }
  }

  private handleRootQueryParams(): void {
    this.queryParamsPreservingApi.setActivePage(this.pageIdentifier);
    this.handleQueryParamsFromUrl();
    this.subscribeToQueryParamsChanges();
    this.subscribeToQueryParamsUpdates();
  }

  private subscribeToQueryParamsUpdates(): void {
    this.queryParamsPreservingApi.activePageQueryParams$
      .pipe(untilDestroyed(this))
      .subscribe((params: QueryParamsValue) => {
        let queryParams: string = null;
        if (params && params.queryParams) {
          queryParams = JSON.stringify(params.queryParams);
        }
        this.router.navigate([], {
          replaceUrl: true,
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge',
          queryParams: { queryParams },
        });
      });
  }

  private handleQueryParamsFromUrl(): void {
    let currentParams: any = this.activatedRoute.snapshot.queryParams['queryParams'];
    if (currentParams) {
      if (typeof currentParams === 'string') {
        currentParams = JSON.parse(currentParams);
      }
      this.queryParamsPreservingApi.update(
        this.pageIdentifier,
        { [QueryParamsProperty.QUERY_PARAMS]: currentParams },
        'merge'
      );
    }
  }

  private definePaginatorPageIndex(): void {
    if (this.filtersChangeSourse === FiltersChangeSourse.api) {
      const page: number = this._paginatorPage$.value;
      this.paginator.pageIndex = page - 1;
    } else {
      this.paginator.pageIndex = 0;
      this._paginatorPage$.next(0);
    }
  }

  filterOnRequest(params: QueryParams) {
    this.definePaginatorPageIndex();
    if (this.querySrv.isRegistered) {
      this.querySrv.initSearch(params);
    } else {
      this.querySrv.isRegistered$
        .pipe(filter(Boolean))
        .pipe(take(1))
        .subscribe(() => this.querySrv.initSearch(params));
    }
  }

  add() {
    this.snackBarSrv.info();
  }

  ngOnDestroy() {
    this.querySrv.destroy();
  }
}
