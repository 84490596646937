import { Pipe, PipeTransform } from '@angular/core';
import { SocialNetworkTitle } from '../../_pages_/user/users/interfaces/social-network/social-network.interface';
import { AppConfig } from '@config';

type Type = 'website' | SocialNetworkTitle | 'hubspot' | 'stripe' | 'wp';

@Pipe({
  name: 'link',
})
export class LinkPipe implements PipeTransform {
  transform(url: string, type: Type = 'website'): unknown {
    if (/http/.test(url)) return url;

    switch (type) {
      case SocialNetworkTitle.INSTAGRAM:
        return `https://www.instagram.com/${url}/`;
      case SocialNetworkTitle.LINKEDIN:
        return `https://www.linkedin.com/in/${url}/`;
      case SocialNetworkTitle.TWITTER:
        return `https://twitter.com/${url}/`;
      case 'hubspot':
        return `https://app.hubspot.com/contacts/4266638/contact/${url}`;
      case 'stripe':
        return `https://dashboard.stripe.com/${AppConfig.production ? '' : 'test/'}customers/${url}`;
      case 'wp':
        return `https://heymama.co/wp-admin/post.php?post=${url}&action=edit`;
      case 'website':
      default:
        return `https://${url}`;
    }
  }
}
