export class CommonSchema {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
}

export class CommonItemSchema extends CommonSchema {
  isPublic: boolean;
}

export class CommonOrderedItemSchema extends CommonItemSchema {
  title: string;
  order: number;
}

export class MinItem {
  _id: string;
  title: string;
}
