<app-filters
  (emitRequest)="filterOnRequest($event)"
  [(filtersChangeSourse)]="filtersChangeSourse"
  [assignedFilters]="assignedFilters"
  [searchKeys]="filterSearchKeys"
  [filters]="filters"
></app-filters>

<div class="card">
  <div class="table-wrapper">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="avatar">
        <th mat-header-cell *matHeaderCellDef>avatar</th>
        <td mat-cell *matCellDef="let element">
          <img class="avatar" [src]="element.avatar" alt="" />
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>phone</th>
        <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>created</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt | moment: 'll' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; let element; columns: displayedColumns"
        [routerLink]="Route.getDetailsPath(element._id)"
      ></tr>
    </table>

    <app-waiting *ngIf="acts.isLoading"></app-waiting>
  </div>

  <app-no-data *ngIf="!acts.isLoading && !dataSource.data.length"></app-no-data>
  <div class="table-paginator-wrapper">
    <app-universal-button btnTitle="add new" icon="add" (click)="add()"></app-universal-button>
    <mat-paginator [pageSizeOptions]="Paginator.PageSizeOpts" showFirstLastButtons></mat-paginator>
  </div>
</div>
