import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QueryStringService {
  private PopulateArray(params: HttpParams, prefix: string, arr: Array<any>) {
    // TODO refact
    // tslint:disable-next-line:forin
    for (const index in arr) {
      const key = prefix + '[' + index + ']';
      const value: any = arr[index];
      params = this.PopulateSearchParams(params, key, value);
    }
    return params;
  }

  private PopulateObject(params: HttpParams, prefix: string, obj: any) {
    Object.keys(obj).forEach((key) => {
      params = this.PopulateSearchParams(params, `${prefix}[${key}]`, obj[key]);
    });
    return params;
  }

  private PopulateSearchParams<T>(params: HttpParams, key: string, value: any) {
    if (value instanceof Array) params = this.PopulateArray(params, key, value);
    else if (value instanceof Date) params = params.set(key, value.toISOString());
    else if (value instanceof Object) params = this.PopulateObject(params, key, value);
    else if (value !== undefined) params = params.set(key, value.toString());

    return params;
  }

  buildParams(obj?: any): HttpParams {
    const params: HttpParams = new HttpParams();
    return obj ? this.PopulateSearchParams(params, '', obj) : params;
  }
}
