import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersComponent } from "@components/filters/filters.component";
import { UniversalModule } from "@modules/universal.module";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "@modules/material.module";
import { WaitingModule } from "@components/waiting/waiting.module";



@NgModule({
  declarations: [
    FiltersComponent
  ],
  exports: [
    FiltersComponent
  ],
  imports: [
    CommonModule,
    UniversalModule,
    ReactiveFormsModule,
    MaterialModule,
    WaitingModule
  ]
})
export class FiltersModule { }
