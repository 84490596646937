import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import { MediaData } from '@components/modals';

@Component({
  selector: 'app-media-dialog',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {


  constructor(
      @Inject(MAT_DIALOG_DATA) public data: MediaData,
      private dialogRef: MatDialogRef<MediaComponent>,
  ) { }

  ngOnInit() {}

  close() {
    if (this.data.type === 'image') this.dialogRef.close();
  }
}
