import { NgModule } from '@angular/core';
import { MomentPipe } from './moment/moment.pipe';
import { StringNormalizerPipe } from './string-normalizer/string-normalizer.pipe';
import { SanitizerPipe } from './sanitizer/sanitizer.pipe';
import { GetErrorMessagePipe } from './error-message/error-message.pipe';
import { LineBreakPipe } from './line-break/line-break.pipe';
import { HumanizePipe } from './humanize/humanize.pipe';
import { OptionalPipe } from './optional/optional.pipe';
import { MapPipe } from './map/map.pipe';
import { PluralPipe } from './plural/plural.pipe';
import { LinkPipe } from './link/link.pipe';
import { ShowErrorPipe } from './show-error/show-error.pipe';
import { TimePipe } from './time/time.pipe';
import { ParseContentPipe } from './parse-content/parse-content.pipe';
import { LastOnlinePipe } from './last-online/last-online.pipe';

@NgModule({
  imports: [],
  declarations: [
    MomentPipe,
    StringNormalizerPipe,
    SanitizerPipe,
    GetErrorMessagePipe,
    LineBreakPipe,
    HumanizePipe,
    OptionalPipe,
    MapPipe,
    PluralPipe,
    LinkPipe,
    ShowErrorPipe,
    TimePipe,
    ParseContentPipe,
    LastOnlinePipe,
  ],
  exports: [
    MomentPipe,
    StringNormalizerPipe,
    SanitizerPipe,
    GetErrorMessagePipe,
    LineBreakPipe,
    HumanizePipe,
    OptionalPipe,
    MapPipe,
    PluralPipe,
    LinkPipe,
    ShowErrorPipe,
    TimePipe,
    ParseContentPipe,
    LastOnlinePipe,
  ],
})
export class PipesModule {}
