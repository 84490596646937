import { EnvInterface } from '@interfaces';

export const environment: EnvInterface = {
  production: false,
  // apiUrl: 'http://ec2-18-191-255-36.us-east-2.compute.amazonaws.com/api',
  apiUrl: 'https://dev-api-hm.wlcmapps.com/api',
  // whitelistedDomains: ['http://ec2-18-191-255-36.us-east-2.compute.amazonaws.com'],
  whitelistedDomains: ['https://dev-api-hm.wlcmapps.com'],
  // webAppUrl: 'http://ec2-3-17-131-145.us-east-2.compute.amazonaws.com',
  webAppUrl: 'https://dev-app-hm.wlcmapps.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
