import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

// MODULES
import { MaterialModule } from './material.module';
import { PipesModule } from '../pipes/pipes.module';
import { RouterModule } from '@angular/router';

// COMPONENTS
import { SnackBarQueueComponent } from '@components/snack-bar-queue/snack-bar-queue.component';
import { SnackBarComponent } from '@components/snack-bar/snack-bar.component';
import { NoDataComponent } from '@components/no-data/no-data.component';
import { StubTableComponent } from '@components/stub-table/stub-table.component';
import { BooleanComponent } from '@components/boolean/boolean/boolean.component';
import { AdminsListComponent } from '../_pages_/admins/pages/admins-list/admins-list.component';
import { MediaComponent } from '@components/media/media.component';
import { OperationStatusComponent } from '@components/boolean/operation-status/operation-status.component';
import { PortalModule } from '@angular/cdk/portal';
import { ModalsModule } from '@components/modals/modals.module';
import { FiltersModule } from '@components/filters/filters.module';
import { WaitingModule } from '@components/waiting/waiting.module';
import { BreadcrumbsModule } from '@components/breadcrumbs/breadcrumbs.module';
import { UniversalModule } from '@modules/universal.module';
import { AddLinkModalComponent } from '@components/add-link-modal/add-link-modal.component';

@NgModule({
  declarations: [
    MediaComponent,
    SnackBarQueueComponent,
    SnackBarComponent,
    NoDataComponent,
    StubTableComponent,
    BooleanComponent,
    AdminsListComponent,
    OperationStatusComponent,
    AddLinkModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    PipesModule,
    ReactiveFormsModule,
    PortalModule,
    ModalsModule,
    FiltersModule,
    WaitingModule,
    UniversalModule,
    BreadcrumbsModule,
  ],
  exports: [
    MaterialModule,
    ReactiveFormsModule,
    MediaComponent,
    SnackBarQueueComponent,
    SnackBarComponent,
    NoDataComponent,
    StubTableComponent,
    BooleanComponent,
    PipesModule,
    WaitingModule,
    AdminsListComponent,
    OperationStatusComponent,
    BreadcrumbsModule,
    UniversalModule,
    AddLinkModalComponent,
  ],
})
export class SharedModule {}
