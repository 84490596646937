import { Component, Input, OnInit } from '@angular/core';
// import { ColorType } from "@const";
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-operation-status',
  templateUrl: './operation-status.component.html',
  styleUrls: ['./operation-status.component.scss'],
})
export class OperationStatusComponent implements OnInit {
  @Input() isTrue: boolean;
  @Input() label: string;

  // style
  @Input() falseColor: ThemePalette = 'warn';
  @Input() falseIcon = 'report_problem';

  constructor() {}

  ngOnInit(): void {}
}
