import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';

import { UserStatus } from '../../_pages_/user/statuses/const/user-status';
import { ColorType } from '@const';

type CtrlBtnType = 'edit' | 'delete' | 'save' | 'visibility';
export type BreadcrumbCtrlButton = CtrlBtnType | BreadcrumbCtrlBtn;

export class BreadcrumbCtrlBtn {
  type: CtrlBtnType | string;
  icon?: string;
  title?: string;
  color?: ColorType;
  tooltip?: string;
  disabled?: boolean;
  state?: boolean; // using for predefined "on"/"off" icon
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit, OnChanges {
  @Input() title = '';
  @Input() ctrlButtons: BreadcrumbCtrlButton[] = [];
  @Input() status: UserStatus;
  @Input() showBack = true;
  @Input() visibility: boolean; // isPublic

  buttons: BreadcrumbCtrlBtn[] = [];

  @Output() clickCtrlBtn = new EventEmitter<BreadcrumbCtrlBtn>();

  constructor(private location: Location) {}

  ngOnInit() {
    this.prepareButtons();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.prepareButtons();
  }

  prepareButtons() {
    this.buttons = this.ctrlButtons.map((ctrlBtn) => {
      const btn: BreadcrumbCtrlBtn = typeof ctrlBtn === 'object' ? ctrlBtn : { type: ctrlBtn };

      btn.state = typeof btn.state !== 'undefined' ? btn.state : this.visibility;

      // predefined
      switch (btn.type) {
        case 'delete':
          btn.color = ColorType.WARN;
          break;
        case 'visibility':
          btn.color = ColorType.ACCENT;
          btn.title = btn.state ? 'hide' : 'publish';
          btn.icon = btn.state ? 'visibility_off' : 'done';
          break;
        default:
          btn.color = btn.color || ColorType.PRIMARY;
      }

      // normalize
      btn.title = btn.title || btn.type;
      btn.icon = btn.icon || btn.type;

      return btn;
    });
  }

  goBack() {
    this.location.back();
  }

  onClickBtn(btn: BreadcrumbCtrlBtn) {
    this.clickCtrlBtn.emit(btn);
  }
}
