import { MatOption, MinItem } from '@interfaces';
import { UserMin } from '../_pages_/user/users';

interface MakeMatOptParam {
  viewVal?: (string & number) | string;
  source?: any;
}

export const makeMatOpt = <T = string>(val: T | MinItem, param?: MakeMatOptParam | number): MatOption<T> => {
  // workaround if using as callback fn
  // @ts-ignore
  const { viewVal, source } = typeof param === 'object' ? param || {} : {};

  if (typeof val === 'object' && val !== null) {
    return {
      value: ((val as MinItem)._id as unknown) as T,
      viewValue: viewVal || (((val as MinItem).title as unknown) as T),
      source,
    };
  }
  if (typeof val === 'string') return { value: val, viewValue: viewVal || val, source };
};

export const makeFullName = (user: UserMin): string => {
  if (!user) return null;
  const result = [];
  if (user.firstName) result.push(user.firstName);
  if (user.lastName) result.push(user.lastName);
  return result.join(' ');
};
