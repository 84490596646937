import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniversalButtonComponent } from './universal-button.component';
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [UniversalButtonComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule],
  exports: [UniversalButtonComponent]
})
export class UniversalButtonModule {}
