<div class="input-container input-type-toggle {{ wrapperCssClass }}">
  <!-- TOGGLE -->
  <mat-slide-toggle [formControl]="fControl" *ngIf="inputType === 'toggle'" [labelPosition]="labelPosition">
    {{ label }}
  </mat-slide-toggle>

  <!-- CHECKBOX -->
  <mat-checkbox [formControl]="fControl" *ngIf="inputType === 'checkbox'" [labelPosition]="labelPosition">
    {{ label }}
  </mat-checkbox>
</div>
