import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-link-modal',
  templateUrl: './add-link-modal.component.html',
  styleUrls: ['./add-link-modal.component.scss'],
})
export class AddLinkModalComponent implements OnInit {
  form: FormGroup = this.formBuilder.group({
    link: ['', Validators.required],
  });

  constructor(private formBuilder: FormBuilder, private matDialogRef: MatDialogRef<AddLinkModalComponent>) {}

  ngOnInit() {}

  submit(): void {
    if (this.form.invalid) return;
    const { link } = this.form.value;
    this.form.value.link = this.handleLink(link);
    this.matDialogRef.close(this.form.value);
  }

  close(): void {
    this.matDialogRef.close();
  }

  private handleLink(link: string): string {
    console.log(link);
    return /^(http|https)\:\/\//.test(link) ? link : `http://${link}`;
  }
}
