<div class="input-container input-type-chips {{ wrapperCssClass }}">
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>

    <mat-chip-list #chipList>
      <mat-chip *ngFor="let chip of chips" [removable]="true" (removed)="remove(chip)">
        {{ chip.viewValue }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        [formControl]="fControl"
        [placeholder]="placeholder"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>

    <!-- ERROR MESSAGE // don't use *ngIf because of ExpressionChangedAfterItHasBeenCheckedError-->
    <mat-error [style.display]="fControl.invalid ? 'inline-block' : 'none'">
      {{ fControl.errors | getErrorMessage | stringNormalizer }}
    </mat-error>
  </mat-form-field>
</div>
