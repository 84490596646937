import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@modules/material.module';

import { UniversalInputComponent } from '@components/universal-input/universal-input.component';
import { UniversalDatepickerComponent } from '@components/universal-datepicker/universal-datepicker.component';
import { UniversalToggleComponent } from '@components/universal-toggle/universal-toggle.component';
import { UniversalChipInputComponent } from '@components/universal-chip-input/universal-chip-input.component';
import { MatAutocompleteOptionsScrollDirective } from '@components/universal-autocomplete/mat-autocomplete-options-scroll/mat-autocomplete-options-scroll.directive';
import { UniversalAutocompleteComponent } from '@components/universal-autocomplete/universal-autocomplete.component';
import { UniversalChipsComponent } from '@components/universal-chips/universal-chips.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { WaitingModule } from '@components/waiting/waiting.module';
import { UniversalButtonModule } from '@components/universal-button/universal-button.module';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';


@NgModule({
  declarations: [
    UniversalInputComponent,
    UniversalDatepickerComponent,
    UniversalToggleComponent,
    UniversalChipInputComponent,
    MatAutocompleteOptionsScrollDirective,
    UniversalAutocompleteComponent,
    UniversalChipsComponent,
  ],
	imports: [
		CommonModule,
		MaterialModule,
		ReactiveFormsModule,
		UniversalButtonModule,
		PipesModule,
		WaitingModule,
		GooglePlaceModule,
	],
  exports: [
    UniversalButtonModule,
    UniversalInputComponent,
    UniversalToggleComponent,
    UniversalDatepickerComponent,
    UniversalAutocompleteComponent,
    UniversalChipsComponent,
  ],
})
export class UniversalModule { }
