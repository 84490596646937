import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { MatOption, QueryParams } from '@interfaces';
import { QUERY_MIN_LENGTH } from '@const';

type InputTypes =
  | 'text'
  | 'password'
  | 'email'
  | 'tel'
  | 'number'
  | 'url'
  | 'textarea'
  | 'select'
  | 'search'
  | 'autocomplete';

@Component({
  selector: 'app-universal-input',
  templateUrl: './universal-input.component.html',
  styleUrls: ['./universal-input.component.scss'],
})
export class UniversalInputComponent implements OnInit, OnDestroy, AfterContentInit {

  constructor() {}
  @Input() fControl: FormControl | AbstractControl;

  // params
  @Input() inputType: InputTypes = 'text';
  @Input() queryMinLength = QUERY_MIN_LENGTH;
  @Input() useDelay = false;
  @Input() min: number; // using with numbers

  isAutocomplete = false;
  // * If chosen 'select' or 'autocomplete' should pass options
  @Input() optionsArr: MatOption[] = [];
  @Input() multiple: boolean; // for 'select'
  @Input() suffix: string;
  @Input() hint: string;

  // style
  @Input() placeholder: string;
  @Input() label: string;
  // @Input() disabled: string; // use by form control .disable()
  @Input() clearBtn = false;
  @Input() showPasswordBtn = false;
  @Input() required = false;
  @Input() wrapperCssClass: string;
  @Input() class: string; // will give effect like <app-universal-input class="input-container short">
  @Input() attrAutocomplete: string; // chrome autocomplete attribute

  // acts
  @Input() isSearching: boolean;

  // Emitters
  @Output() emitRequest = new EventEmitter<QueryParams>();
  @Output() emitSelectChanged = new EventEmitter<null>(); // 'select'

  private tempData = {
    prevSelect: [],
  };
  @Input() compareWith = (opt1, opt2): boolean => opt1?.value?.toLowerCase() === opt2?.value?.toLowerCase();

  ngOnInit() {}

  ngAfterContentInit() {
    this.isAutocomplete = this.inputType === 'autocomplete';
    if (this.useDelay || ['autocomplete', 'search'].includes(this.inputType)) {
      this.fControl.valueChanges
        .pipe(
          untilDestroyed(this),
          map(($event) => {
            // actually it's input event but gets value
            this.isSearching = $event && $event.length >= this.queryMinLength;
            return $event;
          }),
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe((value) => {
          if (
            typeof value === 'string' &&
            !this.fControl.hasError('required') &&
            !this.fControl.hasError('minlength')
          ) {
            this.emitRequest.next(new QueryParams(value));
          }
        });
    }
  }

  displayOption(option) {
    return option ? option.viewValue : undefined;
  }

  togglePasswordVisibility() {
    this.inputType = this.inputType === 'password' ? 'text' : 'password';
  }

  beforeChangeSelect() {
    this.tempData.prevSelect = this.fControl.value;
  }

  onChangeSelect(ev: MatSelectChange) {
    // if first option is reset selections (no value)
    if (this.multiple && !this.optionsArr[0].value) {
      if (!ev.value.length || (this.tempData.prevSelect[0].value && ev.value[0].value === '')) {
        this.fControl.setValue([this.optionsArr[0]]);
      } else if (ev.value[0].value === '') {
        this.fControl.setValue(ev.value.splice(1));
      }
    }

    this.emitSelectChanged.emit();
  }

  onCloseSelect() {
    // this.emitCloseSelect.emit();
  }

  numbersOnly(event): boolean {
    const charCode = event.which || event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  ngOnDestroy() {}
}
