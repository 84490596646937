import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-queue',
  templateUrl: './snack-bar-queue.component.html',
  styleUrls: ['./snack-bar-queue.component.scss']
})
export class SnackBarQueueComponent implements OnInit, OnDestroy {
  count = 0;

  // TODO refact / actions
  constructor(
    public snackbar: MatSnackBar,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.getCount();
  }

  getCount() {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].notifyNumber > 0) this.count++;
    }
  }

  close($event?: any) {
    this.count = this.count - 1;
    if (this.count >= 1) $event.path[2].hidden = true; // target .snack-bar-queue-notification-component
    if (this.count === 0) this.snackbar.dismiss();
  }

  ngOnDestroy() {
    this.close();
  }
}
