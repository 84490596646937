<div class="add-link">
  <mat-toolbar><span>Add link</span> <mat-icon (click)="close()">close</mat-icon></mat-toolbar>

  <form [formGroup]="form" class="add-link__body">
    <app-universal-input
      label="Link"
      inputType="text"
      [fControl]="form.get('link')"
      useInfinityScroll="true"
    ></app-universal-input>
    <app-universal-button
      btnTitle="Save"
      htmlType="submit"
      (emitClick)="submit()"
      [disabled]="form.invalid"
      styleType="raised-button"
    ></app-universal-button>
  </form>
</div>
