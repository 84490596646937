import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural'
})
export class PluralPipe implements PipeTransform {

  transform(amount: number, pluralForm: string = 's'): string {
    return amount > 1 ? pluralForm : ''
  }
}
