import {
  AbstractControlOptions,
  AsyncValidatorFn,
  ValidatorFn,
  FormControl,
  FormArray,
  FormGroup, AbstractControl,
} from '@angular/forms';

/**
 * Model what the `FormBuilder.control` method receives.
 */
export type ValidatorOrOpts = AbstractControlOptions | ValidatorFn[] | ValidatorFn;
/**
 * Model what the `FormBuilder.control` method receives here.
 */
export type AsyncValidator = AsyncValidatorFn | AsyncValidatorFn[];
/**
 * Model what the `FormBuilder._createControl` method receives.
 */
export type ControlConfig<V> =
  | FormControl
  | FormGroup
  | FormArray
  | [V | { value: V; disabled?: boolean }, ValidatorOrOpts?, AsyncValidator?];
/**
 * Model what the `FormBuilder.group` method accepts as its `controlsConfig`
 * parameter.
 */
export type FormModel<T> = {
  [P in keyof T]: ControlConfig<T[P]>;
};

export class FormGroupModel<T> extends FormGroup {
  controls: {
    [key: string]: AbstractControl;
  };
  value: T;
}
