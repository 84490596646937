import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatOption } from '@interfaces';

type Item = MatOption | string;

export interface RemovedChip {
  item: Item;
  index: number;
  arr: Item[];
}

@Component({
  selector: 'app-universal-chips',
  templateUrl: './universal-chips.component.html',
  styleUrls: ['./universal-chips.component.scss'],
})
export class UniversalChipsComponent implements OnInit, AfterViewInit {
  @Input() optionsArr: Item[] = [];
  @Input() isRemovable: boolean;

  @Output() emitRemoved = new EventEmitter<RemovedChip>();

  constructor() {}

  ngOnInit() {

  }

  ngAfterViewInit() {
    // // Normalize options
    // if (!this.optionsArr?.length) return;
    // this.list.push(...this.optionsArr.map((key) => {
    //   if (typeof key === 'object') return key;
    //   if (typeof key === 'string') return { value: key.toLowerCase(), viewValue: key };
    // }));
  }

  remove(item: Item, index: number) {
    this.optionsArr.splice(index, 1);
    this.emitRemoved.emit({ item, index, arr: this.optionsArr });
  }
}
