import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'getErrorMessage'
})
export class GetErrorMessagePipe implements PipeTransform {

  /**
   * @description
   * Pass errors value for init pipe.
   *
   * @usage
   *
   * template
   * ```
   * <mat-form-field>
   *    <input matInput placeholder="Experience title" type="text" formControlName="title">
   *    <mat-error *ngIf="form.get('title').invalid" >{{ form.get('title').errors | getErrorMessage }}</mat-error>
   *  </mat-form-field>
   * ```
   */
  transform(errors: ValidationErrors) {
    if (errors) {
      // tslint:disable
      return errors['required'] && 'Field is required'
        || errors['whitespaces'] && `Shouldn't contain only whitespaces.`
        || errors['alphanumeric'] && `Should contain only alphanumeric characters.`
        || errors['pattern'] && (
          errors['pattern']['requiredPattern'] === '^[0-9]+$' && 'Field should contain only digits.'
          || /(http)/.test(errors['pattern']['requiredPattern']) && 'Website should match https://example.com pattern.'
          || /(@)/.test(errors['pattern']['requiredPattern']) && 'Email should match "username@example.com" pattern.'
          || 'Invalid value.')
        || errors['email'] && 'Email should match "username@example.com" pattern.'
        || errors['matDatepickerParse'] && 'Invalid date format.'
        || (errors['matDatepickerMin'] || errors['matDatepickerMax']) && 'Invalid date.'
        || errors['diffDates'] && `Should be ${this.diffDates(errors)}`
        || errors['min'] && `Should be minimum ${errors['min']['min']}.`
        || errors['max'] && `Should be maximum ${errors['max']['max']}.`
        || errors['minlength'] && `Should contain at least ${errors['minlength']['requiredLength']} chars.`
        || errors['maxlength'] && `Should contain maximum ${errors['maxlength']['requiredLength']} chars.`
        || errors['arrMinLength'] && `Should contain at least ${errors['arrMinLength']['requiredLength']}.`
        || errors['arrMaxLength'] && `Should contain maximum ${errors['arrMaxLength']['requiredLength']}.`
        || errors['selectFromList'] && `Please, select value from the list.`
        || errors['alsoRequired'] && `Please, set ${errors['alsoRequired']}.`
        || errors['valueExist'] && `Value is already exist.`
        || errors['isEqualTo'] && `Should be equal to the ${errors['isEqualTo']}.`
        || errors['specific'];
    }
    return '';
  }

  diffDates(err: ValidationErrors) {
    return err.gt && `greater than "${err.gt}"`
      || err.gte && `greater than or equal to "${err.gte}"`
      || err.lt && `less than "${err.lt}"`
      || err.lte && `less than or equal to "${err.lte}"`
  }
}
