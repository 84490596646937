<div class="input-container {{ cssClass }}">
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>

    <!-- INPUT  // init validation manually because it isn't async! -->
    <input
        matInput
        [min]="minDate"
        [max]="maxDate"
        [formControl]="fControl"
        [required]="required"
        [placeholder]="placeholder"
        (dateChange)="initValidate()"
        [matDatepicker]="picker">

    <!-- CLEAR BUTTON -->
    <button
        type="button" aria-label="Clear"
        mat-button matSuffix mat-icon-button
        (click)="fControl.setValue('')"
        *ngIf="clearBtn && fControl.value">
      <mat-icon>close</mat-icon>
    </button>

    <!-- DATE PICKER OPTIONS -->
    <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="fControl.disabled"></mat-datepicker-toggle>
    <mat-datepicker #picker disabled="false" color="primary" ></mat-datepicker>

    <!-- ERROR MESSAGE // don't use *ngIf because of ExpressionChangedAfterItHasBeenCheckedError-->
    <mat-error [style.display]="fControl.invalid ? 'inline-block' : 'none'">
      {{ fControl.errors | getErrorMessage | stringNormalizer }}
    </mat-error>
  </mat-form-field>
</div>
