import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseContent'
})
export class ParseContentPipe implements PipeTransform {

  transform(content: string): string {
    if (/^<p>.*<\/p>$/.test(content)) {
      // get inner content of "<p></p>"
      return content.slice(3, -4);
    }
  }
}
