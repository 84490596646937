<div class="app-wrapper">
  <router-outlet (activate)="onActivate()"></router-outlet>
</div>

<app-waiting *ngIf="isLoading" fullscreen="true" opaque="true"></app-waiting>

<ng-progress
    [meteor]="progressbarOptions.meteor"
    [color]="progressbarOptions.color"
    [min]="progressbarOptions.min"
    [speed]="progressbarOptions.speed"
    [spinner]="progressbarOptions.spinner"
    [max]="progressbarOptions.max"
    [spinnerPosition]="progressbarOptions.spinnerPosition"
    [direction]="progressbarOptions.direction"
    [thick]="progressbarOptions.thick"
    [trickleSpeed]="progressbarOptions.trickleSpeed"
    [ease]="progressbarOptions.ease">
</ng-progress>
