import { Component, ComponentRef, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';
import { PromptParams, PromptTemplateComponent } from '@components/modals';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-universal-prompt',
  templateUrl: './universal-prompt.component.html',
  styleUrls: ['./universal-prompt.component.scss'],
})
export class UniversalPromptComponent implements OnInit, OnDestroy {
  portal: ComponentPortal<PromptTemplateComponent>;
  componentRef: ComponentRef<PromptTemplateComponent>;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PromptParams,
    @Optional() private dialogRef: MatDialogRef<UniversalPromptComponent>
  ) {}

  ngOnInit() {
    // CREATE portal
    if (this.data.template?.component) {
      this.portal = new ComponentPortal(this.data.template.component);
    }
  }

  onTemplateAttached(ref: CdkPortalOutletAttachedRef) {
    this.componentRef = ref as ComponentRef<PromptTemplateComponent>;
    if (!this.componentRef.instance?.emitSubmit) return;

    this.componentRef.instance.data = this.data.template.data;
    this.componentRef.instance.emitSubmit.pipe(untilDestroyed(this)).subscribe((res) => {
      this.close(res);
    });
  }

  onSubmit() {
    if (this.componentRef?.instance.onSubmit) {
      this.componentRef.instance.onSubmit();
    } else {
      this.close(true);
    }
  }

  close(result: any) {
    this.dialogRef.close(result);
  }

  ngOnDestroy() {}
}
