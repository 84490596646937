import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

type Type = 'html' | 'script' | 'style' | 'url' | 'resourceUrl';

/**
 * Preventing cross-site scripting (XSS)
 *
 * @see https://angular.io/guide/security#xss
 */
@Pipe({
  name: 'sanitizer',
})
export class SanitizerPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(str: string, type: Type = 'resourceUrl') {
    if (!str) return '';
    return type === 'resourceUrl' && this.sanitizer.bypassSecurityTrustResourceUrl(str)
      || type === 'html' && this.sanitizer.bypassSecurityTrustHtml(str)
      || type === 'script' && this.sanitizer.bypassSecurityTrustScript(str)
      || type === 'style' && this.sanitizer.bypassSecurityTrustStyle(str)
      || type === 'url' && this.sanitizer.bypassSecurityTrustUrl(str);
  }
}
