import { Component, Input, OnInit } from '@angular/core';
import { StubMessage } from '@const';

@Component({
  selector: 'app-stub-table',
  templateUrl: './stub-table.component.html',
  styleUrls: ['./stub-table.component.scss']
})
export class StubTableComponent implements OnInit {
  @Input() isNotImplemented = false;
  StubMsg = StubMessage;

  constructor() { }

  ngOnInit(): void {
  }

}
