export const AUTH_ROOT = 'auth';
export const AUTHORIZED_ROOT = '';

// AUTH
const makeAuthRoute = (feature: string) => ({
  ROOT: feature,
  ROOT_PATH: `/${AUTH_ROOT}/${feature}`,
});
export const SignInRoute = makeAuthRoute('sign-in');
export const SignUpRoute = makeAuthRoute('sign-up');
export const ForgotPassRoute = makeAuthRoute('forgot-password');
export const ResetPassRoute = makeAuthRoute('reset-password');

// ROOT
const makeRootRoute = (feature: string) => ({
  ROOT: feature,
  ROOT_PATH: `/${feature}`,
});

export const DashboardRoute = makeRootRoute('dashboard');
export const ProfileRoute = makeRootRoute('');

// FEATURES
export interface ComplexRoute {
  ROOT: string;
  ROOT_PATH: string;
  DETAILS: string;
  ADD: string;
  ADD_PATH: string;
  EDIT: string;
  getDetailsPath(id: string): string;
  getEditPath(id: string): string;
}
const makeComplexRoute = (feature: string): ComplexRoute => ({
  ROOT: feature,
  ROOT_PATH: `/${feature}`,
  DETAILS: `:id`,
  getDetailsPath: (id) => `/${feature}/${id}`,
  ADD: `add`,
  ADD_PATH: `/${feature}/add`,
  EDIT: `:id/edit`,
  getEditPath: (id) => `/${feature}/${id}/edit`,
});

// admins
export const AdminsRoute = makeComplexRoute('admins');
// users
export const UsersRoute = makeComplexRoute('members');
export const PotentialUsersRoute = makeComplexRoute('potential-applicants');
export const StatusesRoute = makeComplexRoute('statuses');
export const PaymentsRoute = makeComplexRoute('payments');
// notifications
export const EmailsRoute = makeComplexRoute('emails');
// payment plans
export const SubscriptionsRoute = makeComplexRoute('subscriptions');
export const FreeTrialsRoute = makeComplexRoute('free-trials');
// promo
export const ReferralLinksRoute = makeComplexRoute('referral-links');
export const SourceRoute = makeComplexRoute('source');
export const CouponsRoute = makeComplexRoute('coupons');
// conversations
export const PostsRoute = makeComplexRoute('posts');
export const RepliesRoute = makeComplexRoute('replies');
export const PostSubjectsRoute = makeComplexRoute('post-types');
export const PostTopicsRoute = makeComplexRoute('post-topics');
export const CommunitiesRoute = makeComplexRoute('communities');
// options
export const LocationOptionsRoute = makeComplexRoute('locations');
// analytics
export const ViewLogsRoute = makeComplexRoute('view-logs');
export const ViewPostLogsRoute = makeComplexRoute('view-post-logs');
