import { Pipe, PipeTransform } from '@angular/core';
import { humanizeMarital, Marital } from '../../_pages_/user/users/const/marital';

type DataType = 'publicStatus' | 'marital' | 'yesNo';

@Pipe({
  name: 'humanize',
})
export class HumanizePipe implements PipeTransform {

  transform(val: string | boolean, dataType: DataType = 'publicStatus'): string {
    switch (dataType) {
      case 'marital':
        return humanizeMarital[val as Marital];
      case 'yesNo':
        return val ? 'yes' : 'no';
      case 'publicStatus':
      default:
        return val ? 'public' : 'hidden';
    }
  }
}
