import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

interface TimeDimension {
  value: number;
  dimension: moment.unitOfTime.Base;
}

const TIME_DIMENSIONS: Array<TimeDimension> = [
  { value: 0, dimension: 'years' },
  { value: 0, dimension: 'months' },
  { value: 0, dimension: 'weeks' },
  { value: 0, dimension: 'days' },
  { value: 0, dimension: 'hours' },
];

@Pipe({ name: 'lastOnline' })
export class LastOnlinePipe implements PipeTransform {
  transform(date: Date | string) {
    if (!date) return;
    const diff = moment.duration(moment().diff(moment(date)));
    const timeDimensions: TimeDimension[] = TIME_DIMENSIONS.map((timeDim: TimeDimension) => ({
      ...timeDim,
      value: Math.floor(diff.as(timeDim.dimension)),
    }));
    const timeDimension: TimeDimension = timeDimensions.find((tD: TimeDimension) => tD.value > 0);
    return this.defineMessageValue(timeDimension);
  }

  private defineMessageValue(timeDimension: TimeDimension): string {
    if (!timeDimension || !timeDimension.value) return 'recently';
    const dimension: string = timeDimension.value > 1 ? timeDimension.dimension : timeDimension.dimension.slice(0, -1);
    return `${timeDimension.value} ${dimension} ago`;
  }
}
