<div class="wrapper">
  <ng-container *ngFor="let item of data; let i = index">
    <div
        *ngIf="item.notifyNumber > 0"
        class="toast-component"
    >
      <div class="icon">
        <span class="si {{ item?.icon }}"></span>
        <span
            class="notify-number"
            [ngClass]="item?.notifyNumber > 99 ? 'above-99' : ''"
        >
        {{ item?.notifyNumber > 99 ? '99+' : item?.notifyNumber }}
      </span>
      </div>
      <div class="text">
        {{ item?.notifyNumber }}
        {{ item?.message }}
        <br />
        <a [routerLink]="item?.routerLinkUrl" (click)="close($event)">
          {{ item?.routerLinkText }}
        </a>
      </div>

      <button
          class="close-button"
          (click)="close($event)"
      >
        <span class="si si-x"></span>
      </button>
    </div>
  </ng-container>
</div>

