import { Params } from '@angular/router';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Page } from '@interfaces';
import * as fromActions from './query-params.actions';

type State = { page: Page; params: Params };

export interface QueryParamsState extends EntityState<State> {
  activePage: Page;
}

export const adapter: EntityAdapter<State> = createEntityAdapter<State>({
  selectId: (state: State) => state.page,
});

export const initialState: QueryParamsState = adapter.getInitialState({
  activePage: null,
});

const queryParamsReducer = createReducer(
  initialState,
  on(fromActions.setActivePage, (state, action) => ({ ...state, activePage: action.page })),
  on(fromActions.updateQueryParams, (state, action) => adapter.upsertOne(handleQueryParams(state, action), state))
);

const handleQueryParams = (state, action): State => {
  const queryParams: State = JSON.parse(JSON.stringify(state.entities[action.page] || null));
  if (queryParams) {
    if (action.handling === 'merge') {
      queryParams.params = { ...queryParams.params, ...action.params };
    }
    if (action.handling === 'preserve') {
      queryParams.params = action.params;
    }
    return queryParams;
  }
  return { page: action.page, params: action.params };
};

export const getActivePage = (state: QueryParamsState) => state.activePage;

export function reducer(state: QueryParamsState | undefined, action: Action) {
  return queryParamsReducer(state, action);
}

export const { selectAll, selectEntities } = adapter.getSelectors();
