import { Page, QueryParamsValue } from '@interfaces';
import { Params, QueryParamsHandling } from '@angular/router';
import { Observable } from 'rxjs';

export abstract class QueryParamsPreservingApi {
  readonly activePage$: Observable<Page>;

  readonly activePageQueryParams$: Observable<QueryParamsValue>;

  abstract update(page: Page, params: Params, handling?: QueryParamsHandling): void;

  abstract setActivePage(page: Page): void;
}
