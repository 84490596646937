export enum SocialNetworkTitle {
  LINKEDIN = 'linkedin',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
}

export interface SocialNetwork {
  title: SocialNetworkTitle;
  urlId: string;
}
