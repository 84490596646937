import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

// lte - less than or equal
// gte - greater than or equal
type DiffDateShouldBe = 'lt' | 'lte' | 'gt' | 'gte';

@Injectable({
  providedIn: 'root',
})
export class CustomValidators {
  static companyNamePattern = /\s{3,}?/g;
  static namePattern = '^([a-zA-Z0-9_])+\\s{0,3}([a-zA-Z0-9_])+\\s{0,3}([a-zA-Z0-9_])*\\s*';
  // static emailPattern = '^([a-zA-Z0-9_-]+\\.)*[a-z0-9_-]+@[a-z0-9_-]+(\\.[a-z0-9_-]+)*\\.[a-z]{2,6}$';
  // static emailPattern = /^(?!.*@.*@.*$)(?!.*@.*\-\-.*\..*$)(?!.*@.*\-\..*$)(?!.*@.*\-$)(.*@.+(\..{1,11})?)$/;
  static phonePattern = '[0-9]+';
  static numberPattern = '[0-9]+';
  // tslint:disable-next-line
  static sitePattern = /^(http[s]?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

  constructor() {}

  static get digits() {
    return Validators.pattern(CustomValidators.numberPattern);
  }

  /**
   * @description
   * Validator that requires the control's value should exist without whitespace only.
   */
  static required(): ValidatorFn {
    return (currCtrl: AbstractControl): { [key: string]: any } => {
      if (!currCtrl?.value) return { required: true };
      return !currCtrl.value.trim().length ? { whitespaces: true } : null;
    };
  }

  /**
   * @description
   * Validator that requires the control's value should be equal to compared control's value.
   *
   * @param controlNameToCompare<string>
   */
  static isEqualTo(controlNameToCompare): ValidatorFn {
    let subscribe = false;
    const toCompare = controlNameToCompare;

    return (currCtrl: AbstractControl): { [key: string]: boolean } => {
      const parent = currCtrl.parent;
      if (parent) {
        if (!subscribe) {
          subscribe = true;
          parent.controls[toCompare].valueChanges.subscribe(() => {
            currCtrl.updateValueAndValidity();
          });
        }
        return currCtrl.value === parent.controls[toCompare].value ? null : { isEqualTo: toCompare };
      }
    };
  }

  /**
   * @description
   * Validator that requires the control's value should be equal to compared control's value.
   */
  static arrLength(arr: any[], min = 1, max?: number): ValidatorFn {
    return (): { [key: string]: any } => {
      const actualLength = arr.length;
      if (actualLength < min) return { arrMinLength: { requiredLength: min, actualLength } };
      if (actualLength > max) return { arrMaxLength: { requiredLength: max, actualLength } };
      return null;
    };
  }

  /**
   * @description
   * Validator that requires the control's value don't match an another passed control's value.
   */
  static overlap(...fieldsNames): ValidatorFn {
    return (currCtrl: AbstractControl): { [key: string]: any } => {
      const parent = currCtrl.parent;
      let invalid = false;

      if (parent) {
        fieldsNames.some((fieldName: string) => {
          invalid = parent.value[fieldName] ? parent.value[fieldName] === currCtrl.value : false;
          return invalid;
        });
      }
      return invalid ? { overlap: invalid } : null;
    };
  }

  /**
   * @description
   * Validator that requires the value should be selected from list of options.
   * optionsArr: {value: any, viewValue: string}
   */
  static get selectFromList(): ValidatorFn {
    return (currCtrl: AbstractControl): { [key: string]: any } => {
      return currCtrl.value && (!currCtrl.value.value || !currCtrl.value.viewValue) ? { selectFromList: true } : null;
    };
  }

  /**
   * @description
   * Validator that requires the passed control's should be valid.
   */
  static alsoRequired(...fieldsNames): ValidatorFn {
    return (currCtrl: AbstractControl): { [key: string]: any } => {
      const parent = currCtrl.parent;
      let invalidControlName = '';

      if (parent && currCtrl.touched) {
        fieldsNames.some((cntrlName: string) => {
          const requiredControl = parent.controls[cntrlName];
          requiredControl.updateValueAndValidity();

          if (currCtrl.value) {
            if (!requiredControl.value) {
              return !!(invalidControlName = cntrlName);
            } else if (requiredControl.invalid) {
              return !!(invalidControlName = cntrlName);
            }
          }
        });
      }
      return invalidControlName ? { alsoRequired: invalidControlName } : null;
    };
  }

  /**
   * @description
   * Validator that requires the passed control's should be valid.
   *
   * ! Don't forget to add refreshStatusOn to the relative control
   *
   * @example
   * ```
   * {
   *  ...
   *  otherCheckbox: [null, CustomValidators.refreshStatusOn('other')],
   *  other: [null, CustomValidators.requiredDependsFrom('otherCheckbox')],
   * }
   * ```
   */
  static requiredDependsFrom(...fieldsNames): ValidatorFn {
    return (currCtrl: AbstractControl): { [key: string]: any } => {
      const parent = currCtrl.parent;
      let invalid = false;

      if (parent) {
        invalid = fieldsNames.some((cntrlName: string) => {
          const requiredControl = parent.controls[cntrlName];
          return requiredControl.value && !currCtrl.value;
        });
      }
      return invalid ? { required: true } : null;
    };
  }

  /**
   * @description
   * Validator will update status on relative control.
   */
  static refreshStatusOn(...fieldsNames): ValidatorFn {
    return (currCtrl: AbstractControl): { [key: string]: any } => {
      const parent = currCtrl.parent;

      if ((parent && currCtrl.value) || (parent && currCtrl.touched)) {
        fieldsNames.some((cntrlName: string) => {
          const requiredControl = parent.controls[cntrlName] as AbstractControl;
          requiredControl.updateValueAndValidity();
          return true;
        });
      }
      return null;
    };
  }

  /**
   * @description
   * Validator that found regExp matches.
   */
  static patterContainMatches(regExp: RegExp): ValidatorFn {
    return (currCtrl: AbstractControl): { [key: string]: any } => {
      return regExp.test(currCtrl.value) ? { patternMatched: true, searchPattern: regExp } : null;
    };
  }

  /**
   * @description
   * Validator that requires the control's value should be latest than to compared control's value.
   */
  static dateLatestThan(controlNameToCompare, pseudonym?: string): ValidatorFn {
    let isSubscribed = false;
    const toCompare = controlNameToCompare;

    return (currCtrl: AbstractControl): { [key: string]: boolean } => {
      const parent = currCtrl.parent;
      if (parent) {
        if (!isSubscribed) {
          parent.controls[toCompare].valueChanges.subscribe(() => {
            currCtrl.updateValueAndValidity();
          });
          isSubscribed = true;
        }
        return currCtrl.value >= parent.controls[toCompare].value ? null : { isOlder: pseudonym || toCompare };
      }
    };
  }

  /**
   * @description
   * Validator that requires the control's value should have valid date to compared control's value or value.
   *
   * @example
   *
   * FormControl
   * ```
   * endDate: [
   *    (model.endDate as Date)?.toISOString(),
   *    [
   *      Validators.required,
   *      CustomValidators.diffDates('gte', 'startDate'),
   *      CustomValidators.diffDates('lt', new Date())
   *    ]
   * ]
   * ```
   */
  static diffDates(shouldBe: DiffDateShouldBe, toCompare: string | Date): ValidatorFn {
    let isSubscribed = false;
    const ctrlNameToCompare = toCompare;
    const date = toCompare;

    return (currCtrl: AbstractControl): { [key: string]: boolean | string | Date } => {
      if (date instanceof Date) {
        if (!currCtrl.value) return null;
        const currDate = currCtrl.value instanceof Date ? currCtrl.value : new Date(currCtrl.value);

        if (shouldBe === 'gt' && currDate > date) return null;
        if (shouldBe === 'gte' && currDate >= date) return null;
        if (shouldBe === 'lt' && currDate < date) return null;
        if (shouldBe === 'lte' && currDate <= date) return null;

        return { diffDates: true, [shouldBe]: date };
      }

      if (currCtrl.parent && typeof ctrlNameToCompare === 'string') {
        const compareCtrl = currCtrl.parent.controls[ctrlNameToCompare];

        if (!isSubscribed) {
          compareCtrl.valueChanges.subscribe(() => {
            currCtrl.updateValueAndValidity();
          });
          isSubscribed = true;
        }

        const currCtrlValue: Date = currCtrl.value ? new Date(currCtrl.value) : null;
        const compareCtrlValue: Date = compareCtrl.value ? new Date(compareCtrl.value) : null;

        if (!currCtrlValue || !compareCtrlValue) return null;
        if (shouldBe === 'gt' && currCtrlValue > compareCtrlValue) return null;
        if (shouldBe === 'gte' && currCtrlValue >= compareCtrlValue) return null;
        if (shouldBe === 'lt' && currCtrlValue < compareCtrlValue) return null;
        if (shouldBe === 'lte' && currCtrlValue <= compareCtrlValue) return null;

        return { diffDates: true, [shouldBe]: ctrlNameToCompare };
      }
    };
  }

  static getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
  }

  static companyNameValidators(): Validators {
    return [
      Validators.required,
      // this.patterContainMatches(this.companyNamePattern)
    ];
  }

  static nameValidators(): Validators {
    return [Validators.required, Validators.pattern(this.namePattern)];
  }

  static emailValidators(): Validators {
    return [
      Validators.required,
      // Validators.pattern(this.emailPattern),
      Validators.email,
    ];
  }

  static phoneValidators(): Validators[] {
    return [Validators.required, Validators.pattern(this.phonePattern)];
  }

  static passwordValidators(): Validators {
    return [Validators.required, Validators.minLength(8), Validators.maxLength(24)];
  }

  /**
   * @description
   * Validator that requires the control's value should be alphanumeric.
   */
  static get alphanumeric(): ValidatorFn {
    return (currCtrl: AbstractControl): { [key: string]: any } => {
      return currCtrl.value && /[^a-zA-Z0-9]/g.test(currCtrl.value)
        ? { alphanumeric: true }
        : null;
    };
  }

  /**
   * @description
   * Validator that requires the control's value should match regex.
   *
   *  !!! Don't multiply! It will be merged
   */
  static includeRegExp(regExp: RegExp, description: string): ValidatorFn {
    return (currCtrl: AbstractControl): { [key: string]: any } => {
      return currCtrl.value && !regExp.test(currCtrl.value) ? { includeRegExp: true, regExp, description } : null;
    };
  }

  /**
   * @description
   * Validator that requires the control's value shouldn't match regex.
   *
   *  !!! Don't multiply! It will be merged
   */
  static excludeRegExp(regExp: RegExp, description: string): ValidatorFn {
    return (currCtrl: AbstractControl): { [key: string]: any } => {
      return currCtrl.value && regExp.test(currCtrl.value) ? { excludeRegExp: true, regExp, description } : null;
    };
  }
}
